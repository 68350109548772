window._ = require("lodash");

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.Popper = require("popper.js").default;
    window.$ = window.jQuery = require("jquery");
    window.Cropper = require("cropperjs");
    window.EXIF = require("../../node_modules/exif-js/exif");
    window.Stepper = require("bs-stepper");

    require("popper.js");

    require("datatables.net-bs4");
    require("datatables.net-buttons-bs4");
    require("datatables.net-responsive-bs4");
    require("datatables.net-rowgroup-bs4");
    require("../../vendor/yajra/laravel-datatables-buttons/src/resources/assets/buttons.server-side");

    require("../../vendor/almasaeed2010/adminlte/build/js/AdminLTE.js");

    require("../../node_modules/summernote/dist/summernote-bs4.js");
    require("bootstrap");
    require("bootstrap4-duallistbox");

    // pull request has been submitted to fix missing translation
    // see https://github.com/summernote/summernote/pull/3979
    // in the meantime, a local copy will be used
    // require('../../node_modules/summernote/dist/lang/summernote-fr-FR.min')
    require("./summernote-fr-FR");

    require("daterangepicker");
    require("bootstrap-colorpicker");
} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require("axios");

window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });
